import {
  Attribute,
  AttributeMap,
  AttributeRelationships,
  DEFAULT_ATTRIBUTE_RELATIONSHIPS,
  ListingPlatform,
  ListingScreen,
} from "@store-platform/types"
import { concat, filter, flatten, reduce, union, uniq, values } from "lodash-es"

export const flattenAttributes = (
  attributes: AttributeRelationships | null,
) => {
  if (!attributes) return []
  return filter(
    uniq(concat(flatten(values(attributes.buckets)), attributes.floating)),
    Boolean,
  ) as Attribute[]
}

export const getAttributeMap = (attributes: AttributeRelationships | null) => {
  const flattened = flattenAttributes(attributes)
  return reduce(
    flattened,
    (acc, attribute) => ({ ...acc, [attribute.slug]: attribute }),
    {},
  ) as AttributeMap
}

export const transformAttributesResponse = (
  relationships: AttributeRelationships | null,
) => {
  if (!relationships) relationships = DEFAULT_ATTRIBUTE_RELATIONSHIPS
  const attributeMap = getAttributeMap(relationships)

  return { relationships, attributeMap }
}

export const getRelatedBuckets = (selectedAttributes: Attribute[] | null) => {
  if (!selectedAttributes?.length) return []
  const relatedBucketsArrays = selectedAttributes
    .map((attribute) => attribute.relatedBuckets)
    .filter((relatedBuckets) => relatedBuckets) as string[][]

  return union(...relatedBucketsArrays)
}

export function parseCategoriesFromAttributes(attributes: string[]): string[] {
  if (!attributes) return []

  return attributes.filter((attr) => attr.startsWith("category."))
}

export function parseScreensFromAttributes(
  attributes: string[],
): ListingScreen[] {
  // return differenceBy(
  //   attributes,
  //   values(attributeRelationships.buckets["Form Factor"]),
  //   "slug",
  // ) as ListingScreen[]
  return attributes.filter((attr) =>
    attr.startsWith("screen."),
  ) as ListingScreen[]
}

export function parsePlatformsFromAttributes(
  attributes: string[],
): ListingPlatform[] {
  return attributes.filter((attr) =>
    attr.startsWith("platform."),
  ) as ListingPlatform[]
}
